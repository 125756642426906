exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cuban-gov-response-js": () => import("./../../../src/pages/cuban-gov-response.js" /* webpackChunkName: "component---src-pages-cuban-gov-response-js" */),
  "component---src-pages-detained-people-js": () => import("./../../../src/pages/detained-people.js" /* webpackChunkName: "component---src-pages-detained-people-js" */),
  "component---src-pages-events-on-july-11-2021-js": () => import("./../../../src/pages/events-on-july-11-2021.js" /* webpackChunkName: "component---src-pages-events-on-july-11-2021-js" */),
  "component---src-pages-human-rights-violations-in-cuba-js": () => import("./../../../src/pages/human-rights-violations-in-cuba.js" /* webpackChunkName: "component---src-pages-human-rights-violations-in-cuba-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-internet-ban-js": () => import("./../../../src/pages/internet-ban.js" /* webpackChunkName: "component---src-pages-internet-ban-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-protests-js": () => import("./../../../src/pages/protests.js" /* webpackChunkName: "component---src-pages-protests-js" */),
  "component---src-pages-submit-protest-js": () => import("./../../../src/pages/submit-protest.js" /* webpackChunkName: "component---src-pages-submit-protest-js" */)
}

